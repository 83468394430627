import {
  AfterContentInit,
  AfterViewInit,
  Injectable,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Roles } from '../enums/roles';
import { AppConfigService } from './app-config.service';
import { environment } from 'src/environments/environment.development';

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  name?: string;
  title?: string | any;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: number;
  active?: boolean;
  bookmark?: boolean;
  havePermissionToShow?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root',
})
export class NavService {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  userPermissions: any = JSON.parse(localStorage.getItem('user')!)
    ?.user_permissions;
  is_manager: boolean =
    JSON.parse(localStorage.getItem('user')!)?.user?.type || false;

  roles = Roles;
  baseUrl = environment.basicUrl
  isYamama = this.appConfigService?.isYamama();
  isAjial = this.appConfigService?.isAjial();

  publicMenuItems: Menu[] = [
    {
      title: 'Home',
      icon: 'home',
      type: 'sub',
      badgeType: 'light-primary',
      active: true,
      children: [
        {
          path: '/dashboard',
          name: this.roles.ShowHome,
          title: 'Home',
          type: 'link',
        },
        {
          path: '/dashboard/default',
          name: this.roles.ShowHome,
          title: 'Dashboard',
          type: 'link',
        },
      ],
    },
    {
      title: 'Roles',
      icon: 'widget',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/roles/roles-settings',
          name: this.roles.ListRoles,
          title: 'RolesModule.Roles Settings',
          type: 'link',
        },
      ],
    },
    {
      title: 'Users',
      icon: 'user',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/users/all-users',
          name: this.roles.ListUsers,
          title: 'UsersModule.All Users',
          type: 'link',
        },
        {
          path: '/users/admin-users',
          name: this.roles.ListUsers,
          title: 'UsersModule.Admin Users',
          type: 'link',
        },
        {
          path: '/users/manager-users',
          name: this.roles.ListUsers,
          title: 'UsersModule.Manager Users',
          type: 'link',
        },
        {
          path: '/users/guardian-users',
          name: this.roles.ListUsers,
          title: 'UsersModule.Guardian Users',
          type: 'link',
        },
        {
          path: '/users/teacher-users',
          name: this.roles.ListUsers,
          title: 'UsersModule.Teacher Users',
          type: 'link',
        },
        {
          path: '/users/student-users',
          name: this.roles.ListUsers,
          title: 'UsersModule.Student Users',
          type: 'link',
        },
        {
          path: '/users/inactive-users',
          name: this.roles.ListUsers,
          title: 'UsersModule.Inactive Users',
          type: 'link',
        },
        {
          path: '/users/add-new-user',
          name: this.roles.CreateUsers,
          title: 'UsersModule.Add New User',
          type: 'link',
        },
      ],
    },
    {
      title: 'QuestionsModule.Questionnaires  Management',
      icon: 'icons',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/questionnaires/list',
          name: this.roles.ListUsers,
          title: 'QuestionsModule.Questionnaires',
          type: 'link',
        },
      ],
    },
    {
      title: 'SuggestionsModule.Suggestions  Management',
      icon: 'icons',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/suggestions/list',
          name: this.roles.ListUsers,
          title: 'SuggestionsModule.Suggestions',
          type: 'link',
        },
      ],
    },
    {
      title: 'Notifications',
      icon: 'email',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/notifications/notifications',
          name: this.roles.ListNotifications,
          title: 'NotificationsModule.Notifications Sent',
          type: 'link',
        },
        {
          path: '/notifications/notifications-texts',
          name: this.roles.ListNotificationTexts,
          title: 'NotificationsModule.Notifications Texts',
          type: 'link',
        },
      ],
    },
    {
      title: 'Settings',
      icon: 'icons',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/settings/school-hub-statistics',
          name: this.roles.ListSchoolHubs,
          title: 'Setup.School Hub Statistics',
          type: 'link',
        },
        {
          path: '/settings/school-hub-setup',
          name: this.roles.ListSchoolHubs,
          title: 'Setup.School Hub Setup',
          type: 'link',
        },
        {
          path: '/settings/gender-setup',
          name: this.roles.ListGenders,
          title: 'Setup.Gender Setup',
          type: 'link',
        },
        {
          path: '/settings/branch-setup',
          name: this.roles.ListBranches,
          title: 'Setup.Branch Setup',
          type: 'link',
        },
        {
          path: '/settings/school-setup',
          name: this.roles.ListSchools,
          title: 'Setup.School Setup',
          type: 'link',
        },
        {
          path: '/settings/group-degree-setup',
          name: this.roles.ListGroupDegrees,
          title: 'Setup.Group Degree Setup',
          type: 'link',
        },
        {
          path: '/settings/degree-setup',
          name: this.roles.ListDegrees,
          title: 'Setup.Degree Setup',
          type: 'link',
        },
        {
          path: '/settings/delete-setup',
          name: this.roles.ListSchoolHubs,
          title: 'Setup.Delete Setup',
          type: 'link',
        },
      ],
    },
    {
      title: 'HR',
      icon: 'learning',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/HR/hr-statistics',
          name: this.roles.ListDepartments,
          title: 'HRModule.HR Stats',
          type: 'link',
        },
        {
          path: '/HR/departments',
          name: this.roles.ListDepartments,
          title: 'HRModule.Departments',
          type: 'link',
        },
        {
          path: '/HR/jobs',
          name: this.roles.ListJobs,
          title: 'HRModule.Jobs',
          type: 'link',
        },
        {
          path: '/HR/universities',
          name: this.roles.ListUniversities,
          title: 'HRModule.Universities',
          type: 'link',
        },
        {
          path: '/HR/staffs',
          name: this.roles.ListStaffs,
          title: 'HRModule.Staffs',
          type: 'link',
        },
        {
          path: '/HR/department-staff',
          name: this.roles.ListDepartmentStaff,
          title: 'HRModule.Department Staff',
          type: 'link',
        },
        // {
        //   path: '/HR/department-staff-list',
        //   name: this.roles.ListDepartmentStaff,
        //   title: 'HRModule.Department Staff List',
        //   type: 'link',
        // },
        {
          path: '/HR/staff-degrees',
          name: this.roles.ListStaffDegrees,
          title: 'HRModule.Staff Degrees',
          type: 'link',
        },
        {
          path: '/HR/jobs-history',
          name: this.roles.ListStaffHistory,
          title: 'HRModule.Jobs History',
          type: 'link',
        },
        {
          path: '/HR/vacations',
          name: this.roles.ListVacations,
          title: 'HRModule.Vacations',
          type: 'link',
        },
        {
          path: '/HR/user-work-hours',
          name: this.roles.ListWorkHours,
          title: 'HRModule.User Work Hours',
          type: 'link',
        },
        {
          path: '/HR/daily-attendance',
          name: this.roles.ListAttendances,
          title: 'HRModule.Daily Attendance',
          type: 'link',
        },
        {
          path: '/HR/rosters',
          name: this.roles.ListDepartments,
          title: 'HRModule.Rosters',
          type: 'link',
        },
        {
          title: 'HRModule.Services Requests',
          type: 'sub',
          active: false,
          children: [
            {
              path: '/HR/vacations-requests',
              name: this.roles.ListVacationRequests,
              title: 'HRModule.Vacations Requests',
              type: 'link',
            },
            {
              path: '/HR/advanced-payment-requests',
              name: this.roles.ListLoanRequests,
              title: 'HRModule.Advanced Payment Requests',
              type: 'link',
            },
          ],
        },
      ],
    },
    {
      title: 'Employees Requests',
      icon: 'to-do',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/employees-requests/vacation',
          name: this.roles.ListVacationRequests,
          title: 'RequestsModule.Vacation',
          type: 'link',
        },
        {
          path: '/employees-requests/loan',
          name: this.roles.ListVacationRequests,
          title: 'RequestsModule.Loan',
          type: 'link',
        },
      ],
    },
    // {
    //   title: 'Ticketing',
    //   icon: 'support-tickets',
    //   type: 'sub',
    //   badgeType: 'light-primary',
    //   active: false,
    //   children: [
    //     {
    //       path: '/ticketing/',
    //       name: this.roles.ListBuildings,
    //       title: 'BuildingsModule.Buildings Stats',
    //       type: 'link',
    //     },
    //   ],
    // },
    {
      title: 'Buildings',
      icon: 'others',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/buildings/buildings-statistics',
          name: this.roles.ListBuildings,
          title: 'BuildingsModule.Buildings Stats',
          type: 'link',
        },
        {
          path: '/buildings/buildings',
          name: this.roles.ListBuildings,
          title: 'BuildingsModule.Buildings',
          type: 'link',
        },
        {
          path: '/buildings/floors',
          name: this.roles.ListFloors,
          title: 'BuildingsModule.Floors',
          type: 'link',
        },
        {
          path: '/buildings/wards',
          name: this.roles.ListWards,
          title: 'BuildingsModule.Wards',
          type: 'link',
        },
        {
          path: '/buildings/rooms-types',
          name: this.roles.ListRoomTypes,
          title: 'BuildingsModule.Rooms Types',
          type: 'link',
        },
        {
          path: '/buildings/rooms',
          name: this.roles.ListRooms,
          title: 'BuildingsModule.Rooms',
          type: 'link',
        },
        {
          path: '/buildings/architectural-structure',
          name: '',
          title: 'BuildingsModule.Architectural structure',
          type: 'link',
        },
      ],
    },
    {
      title: 'Clinics',
      icon: 'ui-kits',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/clinics/check-up',
          name: this.roles.ListClinics,
          title: 'ClinicsModule.New Checkup',
          type: 'link',
        },
        {
          path: '/clinics/clinics',
          name: this.roles.ListClinics,
          title: 'ClinicsModule.Clinic List',
          type: 'link',
        },
        {
          path: '/clinics/medical-staff',
          name: this.roles.ListClinics,
          title: 'ClinicsModule.Medical Staff',
          type: 'link',
        },
        {
          path: '/clinics/allergies',
          name: this.roles.ListClinics,
          title: 'ClinicsModule.Allergies',
          type: 'link',
        },
        {
          path: '/clinics/considerations',
          name: this.roles.ListClinics,
          title: 'ClinicsModule.Considerations',
          type: 'link',
        },
      ],
    },
    {
      title: 'Academic Years',
      icon: 'ecommerce',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/academic-years/academic-years-stats',
          name: this.roles.ListAcademicYears,
          title: 'AcademicYearsModule.Academic Years Stats',
          type: 'link',
        },
        {
          path: '/academic-years/academic-years',
          name: this.roles.ListAcademicYears,
          title: 'AcademicYearsModule.Academic Years',
          type: 'link',
        },
        {
          path: '/academic-years/combined-academic-years',
          name: this.roles.ListAcademicYears,
          title: 'AcademicYearsModule.Combined Academic Years',
          type: 'link',
        },
        {
          path: '/academic-years/academic-degrees',
          name: this.roles.ListAcademicDegrees,
          title: 'AcademicYearsModule.Academic Degrees',
          type: 'link',
        },
        {
          path: '/academic-years/combined-academic-degrees',
          name: this.roles.ListAcademicDegrees,
          title: 'AcademicYearsModule.Combined Academic Degrees',
          type: 'link',
        },
        {
          path: '/academic-years/academic-semesters',
          name: this.roles.ListAcademicSemesters,
          title: 'AcademicYearsModule.Academic Semesters',
          type: 'link',
        },
        {
          path: '/academic-years/academic-terms',
          name: this.roles.ListAcademicTerms,
          title: 'AcademicYearsModule.Academic Terms',
          type: 'link',
        },
        {
          path: '/academic-years/academic-classes',
          name: this.roles.ListAcademicClasses,
          title: 'AcademicYearsModule.Academic Classes',
          type: 'link',
        },
        {
          path: '/academic-years/academic-withdraw',
          name: '',
          title: 'AcademicYearsModule.Academic Withdraw',
          type: 'link',
        },
      ],
    },
    {
      title: 'Educational Settings',
      icon: 'icons',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/educational-settings/subjects',
          name: this.roles.ListSubjects,
          title: 'EducationalSettingsModule.Subjects',
          type: 'link',
        },
      ],
    },
    {
      title: 'Appointments Service',
      icon: 'table',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        // { path: "/appointments/appointments-statistics", name: this.roles.ListAppointmentSections, title: "AppointmentsModule.Appointments Statistics", type: "link" },
        {
          path: '/appointments/appointments-sections',
          name: this.roles.ListAppointmentSections,
          title: 'AppointmentsModule.Appointments Sections',
          type: 'link',
        },
        {
          path: '/appointments/appointments-offices',
          name: this.roles.ListAppointmentOffices,
          title: 'AppointmentsModule.Appointments Offices',
          type: 'link',
        },
        {
          path: '/appointments/appointments-offices-schedules',
          name: this.roles.ListAppointmentOfficesSchedules,
          title: 'AppointmentsModule.Appointments Offices Schedules',
          type: 'link',
        },
        {
          path: '/appointments/appointments-questions',
          name: this.roles.ListAppointmentQuestions,
          title: 'AppointmentsModule.Appointments Questions',
          type: 'link',
        },
      ],
    },
    {
      title: 'Transportation',
      icon: 'maps',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/transportation/vehicles-stats',
          name: this.roles.ListVehicles,
          title: 'TransportationModule.Vehicles Stats',
          type: 'link',
        },
        {
          path: '/transportation/vehicles',
          name: this.roles.ListVehicles,
          title: 'TransportationModule.Vehicles',
          type: 'link',
        },
        {
          path: '/transportation/drivers',
          name: this.roles.ListDrivers,
          title: 'TransportationModule.Drivers',
          type: 'link',
        },
        {
          path: '/transportation/pending-transportation-requests',
          name: this.roles.ListTransportationPlans,
          title: 'TransportationModule.Pending Transportation Requests',
          type: 'link',
        },
        {
          path: '/transportation/sub-contractors',
          name: '',
          title: 'TransportationModule.Sub Contractors',
          type: 'link',
        },
        {
          title: 'Settings',
          type: 'sub',
          active: false,
          children: [
            {
              path: '/transportation/prices',
              name: '',
              title: 'TransportationModule.Prices',
              type: 'link',
            },
            {
              path: '/transportation/trip-adjustments',
              name: '',
              title: 'TransportationModule.Trip Adjustments',
              type: 'link',
            },
            {
              path: '/transportation/transportation-plans',
              name: this.roles.ListTransportationPlans,
              title: 'TransportationModule.Plans',
              type: 'link',
            },
          ],
        },
      ],
    },
    {
      title: 'Maintenance',
      icon: 'support-tickets',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/maintenance/service-request',
          name: this.roles.ListMaintenanceRequests,
          title: 'MaintenanceModule.Service Request',
          type: 'link',
        },
        {
          path: '/maintenance/maintenance-specialties',
          name: this.roles.ListMaintenanceSpecialties,
          title: 'MaintenanceModule.Maintenance Specialties',
          type: 'link',
        },
        {
          path: '/maintenance/skill-degrees',
          name: this.roles.ListSkillDegrees,
          title: 'MaintenanceModule.Skill Degrees',
          type: 'link',
        },
        {
          path: '/maintenance/work-orders',
          name: this.roles.ListWorkOrders,
          title: 'MaintenanceModule.Work Orders',
          type: 'link',
        },
        {
          path: '/maintenance/work-logs',
          name: this.roles.ListWorkLogs,
          title: 'MaintenanceModule.Work Logs',
          type: 'link',
        },
      ],
    },
    {
      title: 'Prices',
      icon: 'ecommerce',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/prices/main-services',
          name: this.roles.ListMainServices,
          title: 'PricesModule.Main Services',
          type: 'link',
        },
        {
          path: '/prices/sub-services',
          name: this.roles.ListSubServices,
          title: 'PricesModule.Sub Services',
          type: 'link',
        },
        {
          path: '/prices/prices-list',
          name: '',
          title: 'PricesModule.Prices List',
          type: 'link',
        },
        {
          path: '/prices/package-setup',
          name: '',
          title: 'PricesModule.Package Setup',
          type: 'link',
        },
      ],
    },
    {
      title: 'EnrollmentRequestsModule.Enrollment Requests',
      icon: 'table',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/enrollment/admission-requests-statistics',
          name: this.roles.RegenerateAdmissionRequest,
          title: 'EnrollmentRequestsModule.Admission Requests Statistics',
          type: 'link',
        },
        {
          path: '/enrollment/enrollment-requests',
          name: this.roles.RegenerateAdmissionRequest,
          title: 'EnrollmentRequestsModule.Enrollment Requests',
          type: 'link',
        },
        {
          path: '/enrollment/enrollment-periods',
          name: this.roles.ListAdmissionPeriods,
          title: 'EnrollmentRequestsModule.Admission Periods',
          type: 'link',
        },
      ],
    },
    {
      title: 'WithdrawalRequestsModule.Withdrawal Requests',
      icon: 'sample-page',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/withdrawal/statistics',
          name: this.roles.ListWithdrawalRequests,
          title: 'WithdrawalRequestsModule.Statistics',
          type: 'link',
        },
        {
          path: '/withdrawal/requests',
          name: this.roles.ListWithdrawalRequests,
          title: 'WithdrawalRequestsModule.New Requests',
          type: 'link',
        },
        {
          path: '/withdrawal/periods',
          name: this.roles.ListWithdrawalPeriods,
          title: 'WithdrawalRequestsModule.Withdrawal Periods',
          type: 'link',
        },
        {
          path: '/withdrawal/register-new-request',
          //name: this.roles.CreateWithdrawalRequest,
          title: 'WithdrawalRequestsModule.Register New Withdrawal Request',
          type: undefined,
        },
      ],
    },
    // {
    //   title: 'TransferModule.Transformation Requests',
    //   icon: 'job-search',
    //   type: 'sub',
    //   badgeType: 'light-primary',
    //   active: false,
    //   children: [
    //     {
    //       path: '/transformation/transfer-requests',
    //       name: this.roles.ListWithdrawalRequests,
    //       title: 'TransferModule.Transformation Requests',
    //       type: 'link',
    //     },
    //   ],
    // },
    {
      title: 'UpgradeRequestsModule.Upgrade Requests',
      icon: 'table',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/upgrade/upgrade-requests-statistics',
          name: this.roles.ListUpgradeRequests,
          title: 'UpgradeRequestsModule.Upgrade Requests Statistics',
          type: 'link',
        },
        {
          path: '/upgrade/upgrade-requests',
          name: this.roles.ListUpgradeRequests,
          title: 'UpgradeRequestsModule.Upgrade Requests',
          type: 'link',
        },
        {
          path: '/upgrade/upgrade-periods',
          name: this.roles.ListUpgradePeriods,
          title: 'UpgradeRequestsModule.Upgrade Periods',
          type: 'link',
        },
      ],
    },
    {
      title: 'ExceptionRequestsModule.Exception Requests',
      icon: 'faq',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/exception/exception-requests',
          name: this.roles.ListExceptionRequests,
          title: 'ExceptionRequestsModule.Exception Requests',
          type: 'link',
        },
      ],
    },
    {
      title: 'Finance',
      icon: 'form',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/finance/banks',
          name: this.roles.ListBanks,
          title: 'BanksModule.Banks',
          type: 'link',
        },
        {
          path: '/finance/bills',
          name: this.roles.ListInvoices,
          title: 'BillsModule.Bills',
          type: 'link',
        },
        {
          path: '/finance/bills-tracking',
          name: this.roles.ListPaymentMonitor,
          title: 'BillsModule.Bills Tracking',
          type: 'link',
        },
        {
          path: '/finance/guardians-wallets',
          name: this.roles.ListInstallmentView,
          title: 'WalletModule.Guardians Wallets',
          type: 'link',
        },
        {
          path: '/finance/installment-view',
          name: this.roles.ListInstallmentView,
          title: 'InstallmentViewModule.Installment View',
          type: 'link',
        },
        {
          title: 'BillingRequestsModule.Incomes',
          icon: "income",
          type: "sub",
          badgeType: 'light-primary',
          active: false,
          children: [
            {
              path: '/billing/income-sources',
              name: this.roles.ListIncomeSources,
              title: 'BillingRequestsModule.Income Sources List',
              type: 'link',
            },
            {
              path: '/billing/income',
              name: this.roles.ListIncome,
              title: 'BillingRequestsModule.Income List',
              type: 'link',
            },
          ],
        },
        {
          title: 'BillingRequestsModule.Expenses',
          icon: "expense",
          type: "sub",
          badgeType: 'light-primary',
          active: false,
          children: [
            {
              path: '/billing/expenses-targets',
              name: this.roles.ListExpensesTargets,
              title: 'BillingRequestsModule.Expenses Targets',
              type: 'link',
            },
            {
              path: '/billing/expenses',
              name: this.roles.ListExpenses,
              title: 'BillingRequestsModule.Expenses List',
              type: 'link',
            },
          ],
        },
        {
          path: '/billing/clients',
          name: this.roles.ListClients,
          title: 'BillingRequestsModule.Clients List',
          type: 'link',
        },
        // {
        //   path: '/billing/contact-officers',
        //   name: this.roles.ListClientContactOfficers,
        //   title: 'BillingRequestsModule.ContactOfficers',
        //   type: 'link',
        // },

      ],
    },
    {
      title: 'Sanad Resend',
      icon: "button",
      type: "sub",
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/resend-sanad/enrollment-requests',
          name: this.roles.ListAdmissionRequests,
          title: 'EnrollmentRequestsModule.Enrollment Requests',
          type: 'link',
        },
        {
          path: '/resend-sanad/upgrade-requests',
          name: this.roles.ListUpgradeRequests,
          title: 'UpgradeRequestsModule.Upgrade Requests',
          type: 'link',
        },
      ],
    },
    // {
    //   title: 'BanksModule.Banks',
    //   icon: 'gallery',
    //   type: 'link',
    //   path: '/banks',
    //   name: this.roles.ListBanks,
    // },
    {
      title: 'ReportsModule.Reports',
      icon: 'knowledgebase',
      type: 'link',
      path: '/reports',
      name: this.roles.ListReports,
    },
    {
      title: 'ClassesModule.Classes',
      icon: 'layout',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/classes/show-students',
          name: this.roles.ListAcademicClasses,
          title: 'ClassesForm.Show Class Students',
          type: 'link',
        },
        {
          path: '/classes/transfer-students',
          name: this.roles.ListAcademicClasses,
          title: 'ClassesForm.Transfer Students To Another Class',
          type: 'link',
        },
      ],
    },
    {
      title: 'StudentsModule.Students',
      icon: 'user',
      type: 'link',
      path: '/students',
      name: this.roles.ListStudents,
    },
    {
      title: 'Supply Chain',
      icon: 'job-search',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          title: 'Settings',
          type: 'sub',
          active: false,
          children: [
            {
              path: '/supply-chain/item-group',
              name: this.roles.ListItemGroups,
              title: 'SupplyChainModule.Item Group',
              type: 'link',
            },
            {
              path: '/supply-chain/unit',
              name: this.roles.ListUnits,
              title: 'SupplyChainModule.Unit',
              type: 'link',
            },
            {
              path: '/supply-chain/item-file',
              name: this.roles.ListItemFiles,
              title: 'SupplyChainModule.Item File',
              type: 'link',
            },
            {
              path: '/supply-chain/transaction-types',
              name: this.roles.ListTransactionTypes,
              title: 'SupplyChainModule.Transaction Types',
              type: 'link',
            },
            {
              path: '/supply-chain/transaction-statuses',
              name: this.roles.ListTransactionStatuses,
              title: 'SupplyChainModule.Transaction Statuses',
              type: 'link',
            },
          ],
        },
        {
          title: 'Stores',
          type: 'sub',
          active: false,
          children: [
            {
              path: '/supply-chain/store-types',
              name: this.roles.ListStoreTypes,
              title: 'SupplyChainModule.Store Types',
              type: 'link',
            },
            {
              path: '/supply-chain/stores',
              name: this.roles.ListStores,
              title: 'SupplyChainModule.Stores',
              type: 'link',
            },
            {
              path: '/supply-chain/store-items',
              name: this.roles.ListStoreItems,
              title: 'SupplyChainModule.Store Items',
              type: 'link',
            },
            {
              path: '/supply-chain/transaction-heads',
              name: this.roles.ListTransactionHeads,
              title: 'SupplyChainModule.Transaction Heads',
              type: 'link',
            },
            {
              path: '/supply-chain/transaction-details',
              name: this.roles.ListTransactionDetails,
              title: 'SupplyChainModule.Transaction Details',
              type: 'link',
            },
          ],
        },
        {
          title: 'Vendors',
          type: 'sub',
          active: false,
          children: [
            {
              path: '/supply-chain/vendors-categories',
              name: this.roles.ListVendorCategories,
              title: 'SupplyChainModule.Vendors Categories',
              type: 'link',
            },
            {
              path: '/supply-chain/vendors',
              name: this.roles.ListVendors,
              title: 'SupplyChainModule.Vendors',
              type: 'link',
            },
            {
              path: '/supply-chain/vendors-officers',
              name: this.roles.ListVendorOfficers,
              title: 'SupplyChainModule.Vendors Officers',
              type: 'link',
            },
            {
              path: '/supply-chain/vendors-phones',
              name: this.roles.ListVendorPhones,
              title: 'SupplyChainModule.Vendors Phones',
              type: 'link',
            },
          ],
        },
      ],
    },
    {
      title: 'AssetsModule.Assets',
      icon: 'others',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/assets/asset-groups',
          name: this.roles.ListAssetGroups,
          title: 'AssetsModule.Asset Groups',
          type: 'link',
        },
        {
          path: '/assets/assets',
          name: this.roles.ListAssets,
          title: 'AssetsModule.Assets',
          type: 'link',
        },
      ],
    },
    {
      title: 'Indebtedness',
      icon: 'ecommerce',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/indebtedness/parent-indebtedness',
          name: this.roles.ListArbitrages,
          title: 'IndebtednessModule.Indebtedness',
          type: 'link',
        },
        {
          path: '/indebtedness/debts',
          name: this.roles.ListArbitrages,
          title: 'IndebtednessModule.ParentDebts',
          type: 'link',
        },
      ],
    },
    {
      title: 'Points',
      icon: 'social',
      type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/points/arbitrages',
          name: this.roles.ListArbitrages,
          title: 'PointsModule.Arbitrages',
          type: 'link',
        },
        {
          path: '/points/arbitrages-distributions',
          name: this.roles.ListArbitrages,
          title: 'PointsModule.Arbitrages Distributions',
          type: 'link',
        },
        {
          path: '/points/payment-periods',
          name: this.roles.ListArbitrages,
          title: 'PointsModule.Payment Periods',
          type: 'link',
        },
        {
          path: '/points/conditions',
          name: this.roles.ListArbitrages,
          title: 'PointsModule.Conditions',
          type: 'link',
        },
        {
          path: '/points/constant-discounts',
          name: this.roles.ListArbitrages,
          title: 'PointsModule.Constant Discounts',
          type: 'link',
        },
        {
          path: '/points/complex-discounts',
          name: this.roles.ListArbitrages,
          title: 'PointsModule.Constant Complex Discounts',
          type: 'link',
        },
        {
          path: '/points/general-discounts',
          name: this.roles.ListArbitrages,
          title: 'PointsModule.General Discounts',
          type: 'link',
        },
      ],
    },
    {
      path: 'debts',
      // name: this.roles.ListArbitrages,
      title: 'Debts.Debts',
      icon: 'ecommerce',
      type: 'link',
    },
  ];

  managerMenuItems: Menu[] = [
    {
      title: 'Home',
      icon: 'home',
      type: 'sub',
      badgeType: 'light-primary',
      active: true,
      children: [
        {
          path: '/dashboard/manager',
          name: this.roles.ShowHome,
          title: 'Manager',
          type: 'link',
        },
        {
          path: '/dashboard/hierarchy',
          name: this.roles.ShowHome,
          title: 'Hierarchy',
          type: 'link',
        },
      ],
    },
    {
      title: 'Students Details',
      icon: 'learning',
      type: 'link',
      path: 'manager/students-details',
      name: this.roles.ShowHome,
    },
    {
      title: 'Combined Statement',
      icon: 'layout',
      type: 'link',
      path: 'manager/combined-details',
      name: this.roles.ShowHome,
    },
    {
      title: 'ExceptionRequestsModule.Exception Requests',
      icon: 'faq',
      type: 'link',
      path: 'exception/exception-requests',
      name: this.roles.ShowHome,
    }
  ];

  MENUITEMS: Menu[] = this.is_manager
    ? this.managerMenuItems
    : this.publicMenuItems;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private appConfigService: AppConfigService
  ) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, 'resize')
      // .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }

    const newMenuItem = {
      title: 'اداره المهام',
      icon: 'widget',
      type: !this.isYamama && !this.isAjial ? 'sub' : undefined,
      //type: 'sub',
      badgeType: 'light-primary',
      active: false,
      children: [
        {
          path: '/componey-managment',
          name: this.roles.ShowHome,
          title: 'اداره المشروعات',
          type: 'link',
        },
        {
          path: '/componey-managment/componey-teams',
          name: this.roles.ShowHome,
          title: 'اداره الفريق',
          type: 'link',
        },
        {
          path: '/componey-managment/componey-tasks',
          name: this.roles.ShowHome,
          title: 'لوحه التحكم',
          type: 'link',
        },
        {
          path: '/componey-managment/componey-charts',
          name: this.roles.ShowHome,
          title: 'احصائيات الشركه',
          type: 'link',
        },
      ],
    };

    // Add the new menu item after the third index
    this.MENUITEMS.splice(3, 0, !this.is_manager ? newMenuItem : {});

    // if (!this.userPermissions) window.location.reload()

    // this.userPermissions = JSON.parse(localStorage.getItem("user")!)?.user_permissions;

    // console.log(this.userPermissions);

    // if (localStorage.getItem("user")) {

    // }

    // this.MENUITEMS.forEach(menuItem => {
    //   // Assuming the permission name is stored in the 'name' property of each permission object
    //   const permissionName = menuItem.title == 'Home' ? `show_${menuItem.title.toLowerCase().replace(/\s/g, "_")}` : `list_${menuItem.title.toLowerCase().replace(/\s/g, "_")}` ;

    //   console.log(permissionName);

    //   if (menuItem.type == 'sub') {
    //     // Check if the user has the permission
    //     menuItem.havePermissionToShow = this.userPermissions?.some((permission: any) => permission.name === permissionName);
    //   }
    // });
    // console.log(this.MENUITEMS);
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  // processMenuItems(): Menu[] {
  //   this.MENUITEMS.forEach(menuItem => {
  //     if (menuItem.children || menuItem.type == 'sub') {
  //       this.processChildItems(menuItem.children)
  //     }

  //     if (menuItem.name) {
  //       const permissionExists = this.userPermissions.some((permission: any) => menuItem.name?.includes(permission.name))
  //       menuItem.havePermissionToShow = permissionExists ? permissionExists : false;
  //     }
  //   })

  //   return this.MENUITEMS;
  // }

  processMenuItems(): any[] {
    this.MENUITEMS.forEach((menuItem) => {
      this.setPermissionForMenuItem(menuItem);
    });
    return this.filterMenuItems(this.MENUITEMS);
  }

  setPermissionForMenuItem(menuItem: any): void {
    if (menuItem.children) {
      menuItem.children.forEach((child: any) => {
        this.setPermissionForMenuItem(child); // Recursively set permissions for child items
      });
    }

    if (menuItem.name) {
      // Check if the path of the menu item includes any permission name
      menuItem.havePermissionToShow = this.userPermissions.some(
        (permission: any) => menuItem.name.includes(permission.name)
      );
    } else {
      // If neither path nor title is specified, default to true
      menuItem.havePermissionToShow = false;
    }
  }

  filterMenuItems(menuItems: any[]): any[] {
    return menuItems.filter((menuItem) => {
      if (menuItem.children) {
        menuItem.children = this.filterMenuItems(menuItem.children);
      }
      return (
        menuItem.havePermissionToShow === true ||
        (menuItem.children && menuItem.children.length > 0)
      );
    });
  }

  // processChildItems(children: any) {
  //   children.forEach((child: any) => {
  //     if (child.name) {
  //       const permissionExists = this.userPermissions.some((permission: any) => child.name?.includes(permission.name))
  //       child.havePermissionToShow = permissionExists ? permissionExists : false;
  //     }
  //   })

  //   return children
  // }

  // filterMenuItems(menuItems: any[]): any[] {
  //   return menuItems.filter(menuItem => {
  //     if (menuItem.children) {
  //       menuItem.children = this.filterMenuItems(menuItem.children);
  //     }
  //     return menuItem.havePermissionToShow == true;
  //   });
  // }

  // filterPermissions(permissions: any) {
  //   this.groupedPermissions = permissions?.reduce((acc: any, permission: any) => {
  //     console.log(acc);
  //     console.log(permission);

  //     const groupName = permission.group_name;

  //     if (!acc[groupName]) {
  //       acc[groupName] = [];
  //     }

  //     acc[groupName].push(permission);
  //     return acc;
  //   }, {});
  //   console.log(this.groupedPermissions);

  //   this.groupedPermissionsList = Object.keys(this.groupedPermissions)
  //   console.log(this.groupedPermissionsList);
  // }

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
